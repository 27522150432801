// assets
import { LoginOutlined, ProfileOutlined, MailOutlined, SettingOutlined, CustomerServiceOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
  MailOutlined,
  SettingOutlined,
  CustomerServiceOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const account = {
  id: 'account',
  title: "Account",
  type: 'group',
  children: [
    {
      id: 'inbox',
      title: 'Inbox',
      type: 'item',
      url: '/inbox',
      icon: icons.MailOutlined,
      breadcrumbs: false
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/settings',
      icon: icons.SettingOutlined,
      breadcrumbs: false
    },
    {
      id: 'support',
      title: 'Support',
      type: 'item',
      url: '/support',
      icon: icons.CustomerServiceOutlined,
      breadcrumbs: false
    }
  ]
};

export default account;
