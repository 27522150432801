// project import
import account from './account';
import dashboard from './dashboard';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, account]
};

export default menuItems;
