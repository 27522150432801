import React from 'react';
import { useRoutes } from 'react-router-dom';
import AuthGuard from './auth-guard/AuthGuard';
import { lazy } from 'react';
// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout/index';

// Render - login
const LoginPage = Loadable(lazy(() => import('pages/authentication/LoginPage')));
const ContactPage = Loadable(lazy(() => import('pages/authentication/ContactPage')));
const LoginByEmailLink = Loadable(lazy(() => import('pages/authentication/LoginByEmailLink')));

// Render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/exec-summary')));
const CustomersListPage = Loadable(lazy(() => import('pages/customers')));
const SingleAssetPage = Loadable(lazy(() => import('pages/asset')));
const SelectByRegionPage = Loadable(lazy(() => import('pages/regions')));
const SelectBySectorPage = Loadable(lazy(() => import('pages/sectors')));
const BusinessUnitListPage = Loadable(lazy(() => import('pages/business-units')));
const ReportsInsightsPage = Loadable(lazy(() => import('pages/reports-insights')));
const InboxPage = Loadable(lazy(() => import('pages/inbox')));
const SettingsPage = Loadable(lazy(() => import('pages/settings')));
const SupportPage = Loadable(lazy(() => import('pages/support')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/account',
      element: <MinimalLayout />,
      children: [
        {
          path: 'handle_login',
          element: <LoginByEmailLink />
        }
      ]
    },
    {
      path: '/auth',
      element: <MinimalLayout />,
      children: [
        {
          path: 'login',
          element: <LoginPage />
        },
        {
          path: 'contact',
          element: <ContactPage />
        }
      ]
    },
    {
      path: '/',
      element: <AuthGuard><MainLayout><DashboardDefault /></MainLayout></AuthGuard>,
    },
    {
      path: '/customers',
      element: <AuthGuard><MainLayout><CustomersListPage /></MainLayout></AuthGuard>,
    },
    {
      path: '/customers/asset/',
      element: <AuthGuard><MainLayout><SingleAssetPage /></MainLayout></AuthGuard>,
    },
    {
      path: '/regions',
      element: <AuthGuard><MainLayout><SelectByRegionPage /></MainLayout></AuthGuard>,
    },
    {
      path: '/sectors',
      element: <AuthGuard><MainLayout><SelectBySectorPage /></MainLayout></AuthGuard>,
    },
    {
      path: '/business-units',
      element: <AuthGuard><MainLayout><BusinessUnitListPage /></MainLayout></AuthGuard>,
    },
    {
      path: '/reports',
      element: <AuthGuard><MainLayout><ReportsInsightsPage /></MainLayout></AuthGuard>,
    },
    {
      path: '/inbox',
      element: <AuthGuard><MainLayout><InboxPage /></MainLayout></AuthGuard>,
    },
    {
      path: '/settings',
      element: <AuthGuard><MainLayout><SettingsPage /></MainLayout></AuthGuard>,
    },
    {
      path: '/support',
      element: <AuthGuard><MainLayout><SupportPage /></MainLayout></AuthGuard>,
    }
  ]);
}
