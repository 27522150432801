import { kOrganizationInfo } from 'config/constants';
import CryptoJS from 'crypto-js';

// Function to convert a hexadecimal string to a WordArray
const hexToWordArray = (hex) => {
    const words = [];
    for (let i = 0; i < hex.length; i += 2) {
        words.push(parseInt(hex.substr(i, 2), 16));
    }
    return CryptoJS.lib.WordArray.create(words);
};

// Constants for encryption
const encryptionKeyHex = process.env.REACT_APP_ENCRYPTION_KEY; // Ensure this is in hexadecimal format

if (!encryptionKeyHex) {
    throw new Error('Encryption key not set. Please set REACT_APP_ENCRYPTION_KEY in your environment variables.');
} else if (encryptionKeyHex.length !== 64) {  // 64 hex characters = 32 bytes
    throw new Error('Invalid encryption key length. The key must be 32 bytes long for AES encryption.');
}

// Convert hexadecimal key to WordArray
const encryptionKey = hexToWordArray(encryptionKeyHex);

// Generate a random 16-byte IV
const generateIv = () => CryptoJS.lib.WordArray.random(16);

// Function to set encrypted data in local storage
const setEncryptedLocalStorage = (key, data) => {
    try {
        const jsonData = JSON.stringify(data);  // Convert data to JSON string

        const iv = generateIv().toString(CryptoJS.enc.Hex);  // Generate IV and convert to hex string
        const encryptedData = CryptoJS.AES.encrypt(jsonData, encryptionKey, {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();

        // Store IV and encrypted data together
        const payload = { iv, data: encryptedData };
        localStorage.setItem(key, JSON.stringify(payload));
        console.log(`Successfully set encrypted data for key: ${key}`);
    } catch (error) {
        console.error('Encryption failed:', error);
    }
};

function parseIfJson(decryptedData) {
    try {
        // Attempt to parse the data as JSON
        return JSON.parse(decryptedData);
    } catch (error) {
        // If it's not valid JSON, return the original data
        return decryptedData;
    }
}

// Function to get decrypted data from local storage
const getEncryptedLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    if (!item) return null;

    try {
        const { iv, data } = JSON.parse(item);  // Parse stored item to get IV and encrypted data
        const bytes = CryptoJS.AES.decrypt(data, encryptionKey, {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);  // Convert decrypted bytes to string
        console.log(`Successfully retrieved data for key: ${key}`);
        return parseIfJson(decryptedData);  // Parse and return JSON data
    } catch (error) {
        console.error('Decryption or JSON parsing failed:', error);
        return null;
    }
};

// Store setup and initial state
const initialState = {
    jwtToken: null,
    items: {} // For generic key-value pairs
};

let state = { ...initialState };

// Action types
const SET_JWT_TOKEN = 'SET_JWT_TOKEN';
const REMOVE_JWT_TOKEN = 'REMOVE_JWT_TOKEN';
const SET_ITEM = 'SET_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const CLEAR = 'CLEAR';

// Reducer function
const reducer = (state, action) => {
    switch (action.type) {
        case SET_JWT_TOKEN:
            return { ...state, jwtToken: action.value };
        case REMOVE_JWT_TOKEN:
            return { ...state, jwtToken: null };
        case SET_ITEM:
            return { ...state, items: { ...state.items, [action.key]: action.value } };
        case REMOVE_ITEM:
            const { [action.key]: _, ...remainingItems } = state.items;
            return { ...state, items: remainingItems };
        case CLEAR:
            return { ...initialState };
        default:
            return state;
    }
};

// Dispatch function to update state
const dispatch = (action) => {
    state = reducer(state, action);
};

// SecureStorage class
class SecureStorage {
    static instance = null;

    constructor() {
        if (SecureStorage.instance) {
            return SecureStorage.instance;
        }
        SecureStorage.instance = this;
    }

    static getInstance() {
        if (!SecureStorage.instance) {
            SecureStorage.instance = new SecureStorage();
        }
        return SecureStorage.instance;
    }

    setJwtToken(value) {
        console.log('Storing JWT token:', value); // Debug line
        setEncryptedLocalStorage('jwtToken', value);  // Removed async/await
        dispatch({ type: SET_JWT_TOKEN, value });
    }

    getJwtToken() {
        const token = getEncryptedLocalStorage('jwtToken');
        console.log('Retrieved JWT token:', token); // Debug line
        return token || state.jwtToken;
    }

    removeJwtToken() {
        localStorage.removeItem('jwtToken');
        dispatch({ type: REMOVE_JWT_TOKEN });
    }

    setItem(key, value) {
        setEncryptedLocalStorage(key, value);  // Removed async/await
        dispatch({ type: SET_ITEM, key, value });
    }

    getItem(key) {
        return getEncryptedLocalStorage(key) || state.items[key];
    }

    removeItem(key) {
        localStorage.removeItem(key);
        dispatch({ type: REMOVE_ITEM, key });
    }

    clear() {
        Object.keys(state.items).forEach(key => localStorage.removeItem(key));
        // Clear JWT token
        this.setJwtToken(undefined)  // If stored in local storage
        this.setItem(kOrganizationInfo, undefined) // If stored in session storage
        dispatch({ type: CLEAR });
    }
}

export { SecureStorage };
