import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { SecureStorage } from '../../securestore.js';
import AuthStatus from './AuthStatus';
import { kOrganizationInfo } from '../../config/constants';

const AuthGuard = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(AuthStatus.CHECKING); // checking | success | fail
    const secureStorage = SecureStorage.getInstance(); // Singleton instance

    useEffect(() => {
        const fetchAuthentication = async () => {
            // Get token from secure storage

            const token = secureStorage.getJwtToken();

            if (!token) {
                setIsAuthenticated(AuthStatus.FAIL);
                return;
            }

            const url = `${process.env.REACT_APP_API_BASEURL}/account/verify_token`;

            try {
                const response = await axios.post(url, { access_token: token });
                const authenticated = response.data.status === AuthStatus.SUCCESS
                    ? AuthStatus.SUCCESS
                    : AuthStatus.FAIL;

                if (authenticated === AuthStatus.SUCCESS) {
                    setIsAuthenticated(AuthStatus.SUCCESS);
                } else {
                    // Clear token if authentication fails
                    secureStorage.removeJwtToken();
                    setIsAuthenticated(AuthStatus.FAIL);
                }
            } catch (err) {
                console.error('Error verifying token:', err);
                // Clear token if there's an error
                secureStorage.removeJwtToken();
                setIsAuthenticated(AuthStatus.FAIL);
            }
        };

        fetchAuthentication();
    }, [secureStorage]);

    if (isAuthenticated === AuthStatus.CHECKING) {
        return <div>Loading...</div>;
    }

    const authguardOff = Boolean(process.env.REACT_APP_AUTHGUARD_OFF);
    return isAuthenticated === AuthStatus.SUCCESS || authguardOff
        ? children
        : <Navigate to="/auth/login" />;
};

export default AuthGuard;
