// assets
import { DashboardOutlined, HomeOutlined, TeamOutlined, EnvironmentOutlined, PieChartOutlined, ClusterOutlined, BulbOutlined } from '@ant-design/icons';

// icons
const icons = {
  HomeOutlined,
  TeamOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  PieChartOutlined,
  ClusterOutlined,
  BulbOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'exec_summary',
      title: 'Executive Summary',
      type: 'item',
      icon: icons.DashboardOutlined,
      url: '/',
      breadcrumbs: false
    },
    {
      id: 'customers',
      title: 'Customers',
      type: 'item',
      icon: icons.TeamOutlined,
      url: '/customers',
      breadcrumbs: true
    },
    {
      id: 'regions',
      title: 'Regions',
      type: 'item',
      url: '/regions',
      icon: icons.EnvironmentOutlined,
      breadcrumbs: false
    },
    {
      id: 'sector',
      title: 'Sectors',
      type: 'item',
      url: '/sectors',
      icon: icons.PieChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'business-unit',
      title: 'Business Units',
      type: 'item',
      url: '/business-units',
      icon: icons.ClusterOutlined,
      breadcrumbs: false
    },
    {
      id: 'report-insight',
      title: 'Report & Insights',
      type: 'item',
      url: '/reports',
      icon: icons.BulbOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
